import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineMedicalCoordinatorPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Medico Coordinatore'

  const description =
    '<b>ING GROUP</b> dispone di un’equipe di medici competenti distribuiti su tutto il territorio nazionale, unitamente a 50 centri medici, per la gestione della sorveglianza sanitaria in tutte le sue forme ed applicazioni.<br> Per le aziende grandi o distribuite sul territorio nazionale assicuriamo la disponibilità di una rete di medici competenti coordinati sotto il controllo di un medico coordinatore, con redazione di un unico PROTOCOLLO SANITARIO, l’assistenza continuativa e l’assistenza in caso di verifica da parte degli enti di vigilanza.<br> A supporto della rete di medici ed a disposizione dei nostri clienti è sempre disponibile la nostra segreteria di settore, per tute le incombenze del caso oltre che per la programmazione e la gestione delle sorveglianze sanitarie, soprattutto per le aziende con svariate sedi operative e lavoratori in esterno (Ex Imprese di pulize, Istituti di Vigilanza, Catene di negozi e supermercati, etc)'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
